@tailwind base;
@tailwind components;
@tailwind utilities;

@keyframes fadeIn {
  from {
      opacity: 0;
      transform: translateY(-40px);
  }
  to {
      opacity: 1;
      transform: translateY(0);
  }
}

.animate-fadeIn {
  animation: fadeIn 1.5s ease-out forwards;
}



